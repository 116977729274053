import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import "../styles/search.scss";

/**
 * Search input component
 */
const Search = () => {
	const history = useHistory();
	const [value, setValue] = useState("");

	const searchOnEnter = (e) => {
		if (e && e.key === "Enter") {
			e.target.blur();
			search();
		}
	};

	const search = () => {
		setValue("");
		history.push(`/books?search=${value.replace(" ", "-")}`);
	};

	return (
		<div className="search">
			<input
				type="text"
				placeholder="Search..."
				value={value}
				onChange={(e) => setValue(e.target.value)}
				onKeyPress={(e) => searchOnEnter(e)}
			/>
			<img src="/images/header/search.svg" alt="Search" onClick={search} />
		</div>
	);
};

export default Search;

import React, { useEffect, useState } from "react";
import Prismic from '@prismicio/client';
import { RichText } from 'prismic-reactjs';

import NotFound from './NotFound';
import { client } from '../utils/prismicHelpers';

import '../styles/news.scss'

const NewsArticle = ({ match }) => {
    const [news, setNews] = useState(null)
    const [notFound, toggleNotFound] = useState(false);

    useEffect(() => {
        const fetchPrismicData = async () => {
            try {
                const newsResults = await client.query(
                    Prismic.Predicates.at('my.news.uid', match.params.title)
                );
                if (newsResults.results) {
                    setNews(newsResults.results[0].data);
                } else {
                    console.warn('There is no content for now. Please try again later.');
                    toggleNotFound(true);
                }
            } catch (error) {
                console.error(error);
                toggleNotFound(true);
            }
        }

        fetchPrismicData();
    }, [match.params.title]);

    if (news) {
        return (
            <div className="news container">
                <div className="news__title">
                    <div className="news__title__content">
                            <p>news</p>
                    </div>
                </div>
                <div className="news__article news__article--single">
                    <h1>{news.title[0].text}</h1>
                    <div className="news__article__separator"></div>
                    <div className="news__article__details">
                        <p>{new Date(news.date).toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' })}</p>
                    </div>
                    <div className="news__article__content">
                        <RichText
                            render={news.content}
                        />
                    </div>
                    <div className="news__article__images">
                        {news.images.map((img, index) => (
                            <img key={index} src={img.image.url} alt={img.image.alt} />
                        ))}
                    </div>
                </div>
            </div>
        );
    } else if (notFound) {
        return <NotFound />;
    }
    return null;
}

export default NewsArticle

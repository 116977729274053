import React, { useState } from "react";

import "../styles/social.scss";

/**
 * Social Media Icons component
 */
const SocialMedia = () => {
	return (
		<div className="social">
			<a
				href="https://www.facebook.com/mike.sager.376"
				className="contact__social__item"
				target="_blank"
				rel="noopener noreferrer"
			>
				<img src="/images/assets/facebook.png" alt="Facebook" />
				<p>The Sager Group</p>
			</a>
			<a
				href="http://twitter.com/therealsager"
				className="contact__social__item"
				target="_blank"
				rel="noopener noreferrer"
			>
				<img src="/images/assets/twitter.png" alt="Twitter" />
				<p>@therealsager</p>
			</a>
			<a
				href="https://www.instagram.com/therealsager"
				className="contact__social__item"
				target="_blank"
				rel="noopener noreferrer"
			>
				<img src="/images/assets/instagram.png" alt="Istagram" />
				<p>@therealsager</p>
			</a>
			<a
				href="https://en.wikipedia.org/wiki/Mike_Sager"
				className="contact__social__item"
				target="_blank"
				rel="noopener noreferrer"
			>
				<img src="/images/assets/wikipedia.png" alt="Wikipedia" />
				<p>Mike Sager</p>
			</a>
			<a href="mailto:info@thesagergroup.net" className="contact__social__item">
				<img src="/images/assets/email.png" alt="Email" />
				<p>info@TheSagerGroup.net</p>
			</a>
		</div>
	);
};

export default SocialMedia;

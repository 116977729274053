import React, { useState } from "react";
import classnames from "classnames";

import SocialMedia from "./components/SocialMedia";

import "./styles/footer.scss";

/**
 * Footer component
 */
const Footer = () => {
	const [email, setEmail] = useState("");
	const [success, setSuccess] = useState(null);
	const [error, setError] = useState(null);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const createRecipientAndSubscribe = () => {
		setIsSubmitting(true);
		const stringData = JSON.stringify([
			{
				email: email,
			},
		]);
		fetch("https://api.sendgrid.com/v3//contactdb/recipients", {
			method: "POST",
			headers: {
				authorization: `Bearer ${process.env.REACT_APP_SG_TOKEN}`,
				"content-type": "application/json",
			},
			body: stringData,
		})
			.then((response) => response.json())
			.then((parsed) => {
				const recipientId = parsed.persisted_recipients.length
					? parsed.persisted_recipients[0]
					: null;
				if (recipientId) {
					subscribeRecipient(recipientId);
				}
			})
			.catch((error) => {
				setError(
					"Newsletter subscription could not be completed. Please try again later."
				);
				setTimeout(() => {
					setError(null);
				}, 5000);
			});
	};

	const subscribeRecipient = (id) => {
		fetch(
			`https://api.sendgrid.com/v3//contactdb/lists/${process.env.REACT_APP_SG_LIST_ID}/recipients/${id}`,
			{
				method: "POST",
				headers: {
					authorization: `Bearer ${process.env.REACT_APP_SG_TOKEN}`,
					"content-type": "application/json",
				},
				body: "{}",
			}
		)
			.then((response) => {
				setSuccess("You were subscribed successfully!");
				setEmail("");
				setIsSubmitting(false);
				setTimeout(() => {
					setSuccess(null);
				}, 5000);
			})
			.catch((error) => {
				setError(
					"Newsletter subscription could not be completed. Please try again later."
				);
				setTimeout(() => {
					setError(null);
				}, 5000);
			});
	};

	return (
		<div className="footer">
			<p className="footer__center">
				Harnessing the Means of Production since 2012
			</p>
			<div className="footer__social">
				<div className="footer__newsletter">
					<p>Subscribe to our Newsletter</p>
					<div className="footer__newsletter__form">
						<input
							type="email"
							required
							name="email"
							placeholder="Enter your email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
						<button
							name="Submit"
							onClick={createRecipientAndSubscribe}
							disabled={isSubmitting}
						>
							{isSubmitting ? "Sending..." : "Submit"}
						</button>
						{(success || error) && (
							<div
								className={classnames("footer__newsletter__form__thankyou", {
									"footer__newsletter__form__thankyou--error": error,
								})}
							>
								{success || error}
							</div>
						)}
					</div>
				</div>
				<div className="footer__icons">
					<SocialMedia />
				</div>
				<div className="footer__col2">
					<a href="https://mikesager.com/" target="_blank">
						<img
							src="/images/assets/the-head-of-Mike-Sager.jpeg"
							alt="The head of Mike Sager"
						/>
					</a>
				</div>
			</div>
			<div className="footer__wrapper">
				<p>
					Copyright © 2022 The Sager Group LLC | site by&nbsp;
					<a
						href="https://www.sfappworks.com/"
						target="_blank"
						rel="noopener noreferrer"
					>
						SF AppWorks
					</a>
				</p>
			</div>
		</div>
	);
};

export default Footer;

import React, { useEffect, useState } from 'react'
import Prismic from '@prismicio/client';
import { RichText } from 'prismic-reactjs';

import NotFound from './NotFound';
import { client } from '../utils/prismicHelpers';

import '../styles/book.scss'

/**
 * Book page component
 */
const Book = ({ match }) => {
    const [notFound, toggleNotFound] = useState(false);
    const [book, setBook] = useState(null)
    const [ebooks, setEbooks] = useState([])
    const [paperbacks, setPaperbacks] = useState([])
    const [hardcovers, setHardcovers] = useState([])

    const formatLinkName = name => {
        let formattedName = name.replace(/_/g, ' ')
        if (formattedName.includes('barnes')) {
            formattedName = 'Barnes&Noble'
        } else if (formattedName.includes('rakuten')) {
            formattedName = 'Rakuten'
        } else if (formattedName.includes("overdrive")) {
			formattedName = "OverDrive";
		}
        return formattedName
    }

    useEffect(() => {
        const fetchPrismicData = async () => {
            try {
                const response = await client.query(
                    Prismic.Predicates.at('my.book.uid', match.params.title)
                );
                if (response.results) {
                    const bookResult = response.results[0]
                    setBook(bookResult);
                    const ebookObj = bookResult.data.ebook_links[0]
                    const paperbackObj = bookResult.data.paperback_links[0]
                    const hardcoverObj = bookResult.data.hardcover_links[0]
                    const ebookLinks = Object.keys(ebookObj).reduce((arr, linkKey) => {
                        if (ebookObj[linkKey].url) {
                            arr.push({
                                name: formatLinkName(linkKey),
                                url: ebookObj[linkKey].url,
                            })
                        }
                        return arr
                    }, [])
                    const paperbackLinks = Object.keys(paperbackObj).reduce((arr, linkKey) => {
                        if (paperbackObj[linkKey].url) {
                            arr.push({
                                name: formatLinkName(linkKey),
                                url: paperbackObj[linkKey].url,
                            })
                        }
                        return arr
                    }, [])
                    const hardcoverLinks = Object.keys(hardcoverObj).reduce((arr, linkKey) => {
                        if (hardcoverObj[linkKey].url) {
                            arr.push({
                                name: formatLinkName(linkKey),
                                url: hardcoverObj[linkKey].url,
                            })
                        }
                        return arr
                    }, [])
                    setEbooks(ebookLinks)
                    setPaperbacks(paperbackLinks)
                    setHardcovers(hardcoverLinks)
                } else {
                    console.warn('No book data at the moment. Please try again later.');
                    toggleNotFound(true);
                }
            } catch (error) {
                console.error(error);
                toggleNotFound(true);
            }
        }

        fetchPrismicData();
    }, [match.params.title]);

    if (book) {
        return (
            <div className="book">
                <div className="book__heading">
                    <img className="book__heading__cover" src={book.data.cover.url} alt={book.data.cover.alt} />
                    <div className="book__heading__data">
                        <h1>{book.data.title[0].text}</h1>
                        <div className="book__heading__divider" />
                        <h2>{!book.data.is_edited_by && 'By ' }{book.data.author[0].text}</h2>
                        {ebooks.length > 0 && (
                            <div className="book__heading__links">
                                <h3>Ebook</h3>
                                {ebooks.map((link, index) => 
                                    <a href={link.url} key={index} target="_blank" rel="noopener noreferrer">
                                        <div className="book__heading__links__item">
                                            {link.name}
                                        </div>
                                    </a>
                                )}
                            </div>
                        )}
                        {paperbacks.length > 0 && (
                            <div className="book__heading__links">
                                <h3>Paperback</h3>
                                {paperbacks.map((link, index) => 
                                    <a href={link.url} key={index} target="_blank" rel="noopener noreferrer">
                                        <div className="book__heading__links__item">
                                            {link.name}
                                        </div>
                                    </a>
                                )}
                            </div>
                        )}
                        {hardcovers.length > 0 && (
                            <div className="book__heading__links">
                                <h3>Hardcover</h3>
                                {hardcovers.map((link, index) => 
                                    <a href={link.url} key={index} target="_blank" rel="noopener noreferrer">
                                        <div className="book__heading__links__item">
                                            {link.name}
                                        </div>
                                    </a>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <div className="book__content">
                    {book.data.content &&
                        <RichText
                            render={book.data.content}
                        />
                    }
                </div>
            </div>
        );
    } else if (notFound) {
        return <NotFound />;
    }
    return null;
}

export default Book

import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import { 
  Books, 
  Makers, 
  Book, 
  About, 
  TeAdiuvaPress, 
  Preview, 
  NotFound, 
  TsgFilms, 
  TsgFilmCategory,
  Contact,
  Home,
  News,
  NewsArticle,
} from './pages'
import Header from './Header'
import Footer from './Footer'

/**
 * Main application component
 */
const App = (props) => {
  return (
    <Fragment>
      <Helmet>
        <script async defer src={`//static.cdn.prismic.io/prismic.js?repo=thesagergroup&new=true`} />
      </Helmet>
      <BrowserRouter>
        <Header />
        <div className="body">
          <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/books' component={Books} />
            <Redirect from="/?book=:id" to="/books/:id" />
            <Route exact path='/stacks-reader-series' component={Books} />
            <Route exact path='/makers' component={Makers} />
            <Route exact path='/about' component={About} />
            <Route exact path='/news' component={News} />
            <Route exact path='/news/:title' component={NewsArticle} />
            <Route exact path='/tsg-films' component={TsgFilms} />
            <Route exact path='/te-adiuva-press' component={TeAdiuvaPress} />
            <Route path='/books/:title' component={Book} />
            <Route path='/tsg-films/:title' component={TsgFilmCategory} />
            <Route path='/contact' component={Contact} />
            <Route exact path='/preview' component={Preview} />
            <Route component={NotFound} />
          </Switch>
        </div>
        <Footer />
      </BrowserRouter>
    </Fragment>
  )
}

export default App

import React, { useEffect, useState, useRef } from "react";
import Prismic from "@prismicio/client";
import { useLocation, useHistory } from "react-router-dom";

import NotFound from "./NotFound";
import Book from "../components/Book";
import { client } from "../utils/prismicHelpers";

import "../styles/books.scss";

/**
 * Books listing component
 */
const Books = () => {
	const [notFound, toggleNotFound] = useState(false);
	const [books, setBooks] = useState(null);
	const [hoveredIndex, setHoveredIndex] = useState(null);
	const [bookHeight, setBookHeight] = useState(null);
	const [noWood, setNoWood] = useState(0);
	const [filter, setFilter] = useState({ value: "|all", label: "All Books" });
	const [isStacksReader, setIsStacksReader] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const bookItem = useRef(null);
	const location = useLocation();
	const history = useHistory();

	const handleResize = () => {
		if (bookItem.current) {
			const height = bookItem.current.offsetHeight;
			height !== bookHeight && setBookHeight(height);
		}
		const perRow = window.innerWidth <= 768 ? 2 : 4;
		setNoWood(Math.ceil(books.length / perRow));
	};

	useEffect(() => {
		let splitLocation = { value: "|all", label: "All Books" };
		setIsStacksReader(false);
		// For Stacks Reader page
		if (location.pathname === "/stacks-reader-series") {
			const splitArray = location.pathname.split("/")[1];
			splitLocation = {
				key: "tag",
				value: `tag|${splitArray}`,
				label: splitArray.split("-").join(" "),
			};
			setIsStacksReader(true);
		} else if (location.search.length) {
			// For Books page
			const locationArray = location.search.split("?")[1].split("=");
			splitLocation = {
				key: locationArray[0],
				value: `${locationArray[0]}|${locationArray[1]}`,
				label: locationArray[1].split("-").join(" "),
			};
		}
		setFilter(splitLocation);

		const fetchPrismicData = async () => {
			setIsLoading(true);
			try {
				let bookResults = {};
				switch (splitLocation.key) {
					case "tag":
						bookResults = await client.query(
							Prismic.Predicates.at("document.tags", [splitLocation.label]),
							{ pageSize: 200, orderings: "[my.book.priority desc]" }
						);
						break;
					case "author":
						bookResults = await client.query(
							Prismic.Predicates.fulltext(
								"my.book.author",
								splitLocation.label
							),
							{ pageSize: 200, orderings: "[my.book.priority desc]" }
						);
						break;
					case "search":
						const authorResults = await client.query(
							Prismic.Predicates.fulltext(
								"my.book.author",
								splitLocation.label
							),
							{ pageSize: 200, orderings: "[my.book.priority desc]" }
						);
						const titleResults = await client.query(
							Prismic.Predicates.fulltext("my.book.title", splitLocation.label),
							{ pageSize: 200, orderings: "[my.book.priority desc]" }
						);
						const excerptResults = await client.query(
							Prismic.Predicates.fulltext(
								"my.book.excerpt",
								splitLocation.label
							),
							{ pageSize: 200, orderings: "[my.book.priority desc]" }
						);
						bookResults.results = [
							...authorResults.results,
							...titleResults.results,
							...excerptResults.results,
						];
						break;
					default:
						bookResults = await client.query(
							Prismic.Predicates.at("document.type", "book"),
							{ pageSize: 200, orderings: "[my.book.priority desc]" }
						);
						break;
				}

				if (bookResults.results) {
					const sortingCriteria = bookResults.results.reduce((arr, book) => {
						const foundCat = book.data.book_order.find(
							(b) => b.category === splitLocation.label
						);
						arr.push({
							...book,
							prio: foundCat ? foundCat.order : book.data.priority,
						});
						return arr;
					}, []);
					const sorted = sortingCriteria.sort((a, b) =>
						a.prio > b.prio ? -1 : 1
					);
					setBooks(sorted);
					const perRow = window.innerWidth <= 768 ? 2 : 4;
					setNoWood(Math.ceil(bookResults.results.length / perRow));
					if (bookItem.current) {
						setBookHeight(bookItem.current.offsetHeight);
					}
				} else {
					console.warn(
						"Shelves are empty at the moment. Please try again later."
					);
					toggleNotFound(true);
				}
			} catch (error) {
				console.error(error);
				toggleNotFound(true);
			}
			setIsLoading(false);
		};

		fetchPrismicData();
	}, [location]);

	useEffect(() => {
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	});

	const selectFilter = (value) => {
		const splitValue = value.split("|");
		if (splitValue[0].length) {
			history.push(`?${splitValue[0]}=${splitValue[1]}`);
		} else {
			history.push("/books");
		}
	};

	if (books) {
		return (
			<div className="books">
				<div className="books__filter">
					<select
						value={filter.value}
						onChange={(e) => selectFilter(e.target.value)}
						className="books__filter__list"
						disabled={isStacksReader || filter.key === "search"}
					>
						<option value="|all">All Books</option>
						<option value="tag|new-releases">New Releases</option>
						<option value="tag|fiction">Fiction</option>
						<option value="tag|non-fiction">Non Fiction</option>
						<option value="tag|stacks-reader-series">
							Stacks Reader Series
						</option>
						<option value="tag|women-in-journalism-series">
							Women in Journalism Series
						</option>
						<option value="author|mike-sager">Mike Sager</option>
					</select>
					<div className="books__filter__list__value">
						<p>
							{filter.key === "search" ? "Search: " : ""}
							{filter.label}
						</p>
						{!isStacksReader && !(filter.key === "search") && (
							<img src="/images/assets/down-arrow.svg" alt="Filter toggle" />
						)}
					</div>
				</div>
				<div className="shelves">
					<div className="shelves__items">
						{books &&
							books.map((book, index) => (
								<Book
									key={index}
									index={index}
									book={book}
									href={`/books/${book.uid}`}
									hovered={hoveredIndex}
									onMouseEnter={() => setHoveredIndex(index)}
									onMouseLeave={() => setHoveredIndex(null)}
									ref={bookItem}
								/>
							))}
					</div>
					{!books.length && <div className="books__empty">No books found</div>}
					{[...Array(noWood)].map((item, index) => {
						let topValue = (index + 1) * bookHeight - 10;
						const topMargin = window.innerWidth > 768 ? 150 : 100;
						if (index > 0) {
							topValue += topMargin * index;
						}
						const style = {
							top: `${topValue}px`,
						};
						return (
							<div className="books__wood" key={index} style={style}>
								<div className="books__wood__dark" />
								<div className="books__wood__light" />
							</div>
						);
					})}
				</div>
				{isLoading && (
					<div className="books__loading">
						<svg class="spinner" viewBox="0 0 50 50">
							<circle
								class="path"
								cx="25"
								cy="25"
								r="20"
								fill="none"
								stroke-width="5"
							></circle>
						</svg>
					</div>
				)}
			</div>
		);
	} else if (notFound) {
		return <NotFound />;
	}
	return null;
};

export default Books;

import React, { useEffect, useState } from "react";
import Prismic from '@prismicio/client';
import { Link } from 'react-router-dom'

import NotFound from './NotFound';
import { client } from '../utils/prismicHelpers';

import '../styles/news.scss'

const News = () => {
    const [news, setNews] = useState(null)
    const [notFound, toggleNotFound] = useState(false);

    useEffect(() => {
        const fetchPrismicData = async () => {
            try {
                const newsResults = await client.query(
                    Prismic.Predicates.at('document.type', 'news'),
                    { orderings: '[my.news.date desc]' }
                );
                if (newsResults.results) {
                    setNews(newsResults.results);
                } else {
                    console.warn('There is no content for now. Please try again later.');
                    toggleNotFound(true);
                }
            } catch (error) {
                console.error(error);
                toggleNotFound(true);
            }
        }

        fetchPrismicData();
    }, []);

    if (news) {
        return (
            <div className="news container">
              <div className="news__title">
                  <div className="news__title__content">
                        <p>news</p>
                  </div>
              </div>
              <div className="news__list">
                  {news.map((art, index) => 
                    <div className="news__article" key={index}>
                        <h1>{art.data.title[0].text}</h1>
                        <div className="news__article__separator"></div>
                        <div className="news__article__details">
                            <p>{new Date(art.data.date).toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' })}</p>
                            <Link to={`/news/${art.uid}`}>
                                <p className="news__article__details__more">&gt;&gt; Read More</p>
                            </Link>
                        </div>
                    </div>
                  )}
              </div>
            </div>
        );
    } else if (notFound) {
        return <NotFound />;
    }
    return null;
}

export default News

import React from 'react'
import classnames from 'classnames'
import { RichText } from 'prismic-reactjs';

import '../styles/makers.scss'

/**
 * Maker component for showing on Makers page
 */
const Maker = ({ maker, isExpanded, onClick, onClose, onPrev, onNext }) => {
    return (
        <div 
            className="makers__person"
        >
            <div className="makers__person__small" onClick={onClick}>
                <img src={maker.data.headshot.url} alt={maker.data.headshot.alt} />
                <div className="makers__person__details">
                    <div className="makers__person__details__name">
                        <h1>{maker.data.first_name[0].text}&nbsp;</h1>
                        <h1>{maker.data.last_name[0].text}</h1>
                    </div>
                    <div className="makers__person__details__separator"></div>
                    <div className="makers__person__details__type">
                        {maker.data.role[0] ? maker.data.role[0].text : 'Author'}
                    </div>
                </div>
            </div>
            <div className={classnames("makers__person__card", {
                "makers__person__card--show": isExpanded,
            })}>
                <div className="makers__person__card__content">
                    <div className="makers__person__card__close">
                        <img src="/images/assets/left-arrow-blk.svg" alt="Previous maker" onClick={onPrev}/>
                        <img src="/images/assets/close.svg" alt="Close maker" onClick={onClose}/>
                        <img src="/images/assets/right-arrow-blk.svg" alt="Next maker" onClick={onNext}/>
                    </div>
                    <img src={maker.data.headshot.url} alt={maker.data.headshot.alt} />
                    <div className={classnames("makers__person__details", {
                        "makers__person__details--centered": isExpanded,
                    })}>
                        <div className="makers__person__details__name">
                            <h1>{maker.data.first_name[0].text}</h1>
                            <h1>&nbsp;{maker.data.last_name[0].text}</h1>
                        </div>
                        <div className="makers__person__details__separator"></div>
                        <div className="makers__person__details__type">
                            {maker.data.role[0] ? maker.data.role[0].text : 'Author'}
                        </div>
                    </div>
                    <div className="makers__person__content">
                        <RichText
                            render={maker.data.bio}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Maker

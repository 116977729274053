import React, { useEffect, useState } from 'react'
import Prismic from '@prismicio/client';
import { Link } from 'react-router-dom'

import NotFound from './NotFound';
import { client } from '../utils/prismicHelpers';

import '../styles/tsg-films.scss'

/**
 * TSG Films page component
 */
const TsgFilms = () => {
    const [notFound, toggleNotFound] = useState(false);
    const [content, setContent] = useState(null);
    const [hovered, setHovered] = useState(null);

    useEffect(() => {
        const fetchPrismicData = async () => {
            try {
                const response = await client.query(
                    Prismic.Predicates.at('document.type', 'tsg_films')
                );
                if (response.results) {
                    setContent(response.results[0].data);
                } else {
                    console.warn('Shelves are empty at the moment. Please try again later.');
                    toggleNotFound(true);
                }
            } catch (error) {
                console.error(error);
                toggleNotFound(true);
            }
        }

        fetchPrismicData();
    }, []);

    if (content) {
        return (
            <div className="films container">
                <div className="films__title">
                    <div className="films__title__content">
                        <h1>tsg films</h1>
                    </div>
                </div>
                <p className="films__bio">{content.description[1].text}</p>
                <div className="films__categories">
                    {content.categories.map((cat, index) => 
                        {
                            const catSlug = cat.category_name[0].text.toLowerCase().trim().replaceAll(' ', '-');
                            return (
                                <Link to={`/tsg-films/${catSlug}`} key={index}>
                                    <div 
                                        className="films__categories__category" 
                                        onMouseEnter={() => setHovered(index)}
                                        onMouseLeave={() => setHovered(null)}
                                    >
                                        {hovered === index ? (
                                            <img className="films__categories__category__prev" src={`/images/films/${catSlug}.gif`} alt={`${cat.category_name[0].text} videoss`} />
                                        ) : (
                                            <p>{cat.category_name[0].text}</p>
                                        )} 
                                    </div>
                                </Link>
                            )
                        }
                    )}
                    <div className="books__wood books__wood--category" style={{ top: '970px' }}>
                        <div className="books__wood__dark" />
                        <div className="books__wood__light" />
                    </div>
                    <div className="books__wood books__wood--category" style={{ top: '1392px' }}>
                        <div className="books__wood__dark" />
                        <div className="books__wood__light" />
                    </div>
                    <div className="books__wood books__wood--category">
                        <div className="books__wood__dark" />
                        <div className="books__wood__light" />
                    </div>
                    <div className="books__wood books__wood--category">
                        <div className="books__wood__dark" />
                        <div className="books__wood__light" />
                    </div>
                </div>
            </div>
        );
    } else if (notFound) {
        return <NotFound />;
    }
    return null;
}

export default TsgFilms

import React, { useEffect, useState } from 'react'
import Prismic from '@prismicio/client';
import { RichText } from 'prismic-reactjs';

import NotFound from './NotFound';
import { client } from '../utils/prismicHelpers';

import '../styles/about.scss'

/**
 * About page component
 */
const About = () => {
    const [notFound, toggleNotFound] = useState(false);
    const [about, setAbout] = useState(null)

    useEffect(() => {
        const fetchPrismicData = async () => {
            try {
                const aboutResults = await client.query(
                    Prismic.Predicates.at('document.type', 'about')
                );
                if (aboutResults.results) {
                    setAbout(aboutResults.results[0]);
                } else {
                    console.warn('There is no content for now. Please try again later.');
                    toggleNotFound(true);
                }
            } catch (error) {
                console.error(error);
                toggleNotFound(true);
            }
        }

        fetchPrismicData();
    }, []);

    if (about) {
        return (
            <div className="about container">
                <div className="about__title">
                    <div className="about__title__content">
                        <p>{about.data.page_title[0].text}</p>
                    </div>
                </div>
                <div className="about__content">
                    {about.data.content.map((p, index) =>
                        <div className="about__content__paragraph" key={index}>
                            <RichText
                                render={p.paragraph}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    } else if (notFound) {
        return <NotFound />;
    }
    return null;
}

export default About

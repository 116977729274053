import React from 'react'
import classnames from 'classnames';

import '../styles/book.scss'

/**
 * Book component for showing on shelf
 */
const Book = React.forwardRef(({ onMouseEnter, onMouseLeave, index, href, book, hovered, isCategory, categoryName }, ref) => {
    return (
        <div 
            className="shelves__book"
            onMouseEnter={window.innerWidth > 768 ? onMouseEnter : () => {}}
            onMouseLeave={window.innerWidth > 768 ? onMouseLeave : () => {}}
            ref={ref}
        >
            <a href={href}>
                <div className="shelves__book__shadow" />
                {isCategory ? (
                    <div className="shelves__book__black">
                        <p>{categoryName}</p>
                    </div>
                ) : (
                    <>
                        <img 
                            src={book.data.cover.url} 
                            alt={book.data.cover.alt} 
                            className={classnames('shelves__book__cover', {
                                'shelves__book__cover--hovered': hovered === index
                            })}
                        />
                        <div className="shelves__book__page">
                            <h1>{book.data.title[0] ? book.data.title[0].text : ''}</h1>
                            {book.data.coming_soon ? (
                                <img src="/images/home/comingsoon.png" alt="Coming Soon" />
                            ) : (
                                <p>{book.data.excerpt[0] ? book.data.excerpt[0].text : ''}</p>
                            )}
                            <h2>{book.data.author[0] ? book.data.author[0].text : ''}</h2>
                        </div>
                    </>
                )}
            </a>
        </div>
    );
})

export default Book

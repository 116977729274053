import React, { useEffect, useState } from 'react'
import Prismic from '@prismicio/client';

import NotFound from './NotFound';
import Maker from '../components/Maker'
import { client } from '../utils/prismicHelpers';

import '../styles/makers.scss'

/**
 * Makers page component
 */
const Makers = () => {
    const [notFound, toggleNotFound] = useState(false);
    const [makers, setMakers] = useState(null);
    const [expanded, setExpanded] = useState(null)

    useEffect(() => {
        const fetchPrismicData = async () => {
            try {
                const makerResults = await client.query(
                    Prismic.Predicates.at('document.type', 'maker'),
                    { pageSize : 100, orderings: '[my.maker.last_name, my.maker.first_name]' },
                );
                if (makerResults.results) {
                    setMakers(makerResults.results);
                } else {
                    console.warn('There are no Makers at the moment. Please try again later.');
                    toggleNotFound(true);
                }
            } catch (error) {
                console.error(error);
                toggleNotFound(true);
            }
        }

        fetchPrismicData();
    }, []);

    if (makers) {
        return (
            <>
                <div className="makers__title">
                    <div className="makers__title__content">
                        <p>makers</p>
                    </div>
                </div>
                <div className="makers">
                    {makers.map((maker, index) => (
                        <Maker 
                            onClick={() => setExpanded(index)}
                            onClose={() => setExpanded(null)}
                            onPrev={() => setExpanded(index - 1)}
                            onNext={() => setExpanded(index + 1)}
                            key={index}
                            maker={maker}
                            isExpanded={expanded === index}
                        />
                    ))}
                </div>
            </>
        );
    } else if (notFound) {
        return <NotFound />;
    }
    return null;
}

export default Makers

import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import classnames from "classnames";

import Search from "./components/Search";

import "./styles/header.scss";

/**
 * Header component
 */
const Header = () => {
	const location = useLocation();
	const history = useHistory();
	const [scroll, setScroll] = useState(false);
	const [showMenu, setShowMenu] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 220 && !scroll) {
				setScroll(true);
			}
			if (window.scrollY === 0) {
				setScroll(false);
			}
		};
		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, [scroll]);

	useEffect(() => {
		setShowMenu(false);
		window.scrollTo(0, 0);
		if (location.search && location.search.includes("?book=")) {
			history.push(`/books/${location.search.split("=")[1]}`);
		}
	}, [location]);

	return (
		<>
			<div
				className={classnames("header", {
					"header--big": location.pathname === "/" && !scroll,
				})}
			>
				<div
					className={classnames("header__wrapper", {
						"header__wrapper--big": location.pathname === "/" && !scroll,
					})}
				>
					<div className="header__wrapper__desktop">
						<NavLink activeStyle={{ color: "white" }} exact to="/">
							<span className="header__wrapper__text">Home</span>
						</NavLink>
						<NavLink activeStyle={{ color: "white" }} exact to="/books">
							<span className="header__wrapper__text">Books</span>
						</NavLink>
						<NavLink activeStyle={{ color: "white" }} exact to="/makers">
							<span className="header__wrapper__text">Makers</span>
						</NavLink>
						<NavLink
							activeStyle={{ color: "white" }}
							exact
							to="/stacks-reader-series"
						>
							<span className="header__wrapper__text">
								Stacks Reader Series
							</span>
						</NavLink>
						<NavLink
							activeStyle={{ color: "white" }}
							exact
							to="/"
							className="header__wrapper__logo"
						>
							<img
								src="/images/header/thesagergroup_logo.png"
								alt="The Sager Group Logo"
							/>
						</NavLink>
						<NavLink activeStyle={{ color: "white" }} exact to="/tsg-films">
							<span className="header__wrapper__text">TSG Films</span>
						</NavLink>
						<NavLink activeStyle={{ color: "white" }} exact to="/about">
							<span className="header__wrapper__text">The Group</span>
						</NavLink>
						<NavLink activeStyle={{ color: "white" }} exact to="/news">
							<span className="header__wrapper__text">News</span>
						</NavLink>
						<NavLink
							activeStyle={{ color: "white" }}
							exact
							to="/te-adiuva-press"
						>
							<span className="header__wrapper__text">About</span>
						</NavLink>
						<NavLink activeStyle={{ color: "white" }} exact to="/contact">
							<span className="header__wrapper__text">Contact</span>
						</NavLink>
					</div>
					<div className="header__wrapper__mobile">
						<div className="header__wrapper__mobile__logo">
							<NavLink
								activeStyle={{ color: "white" }}
								exact
								to="/"
								className="header__wrapper__logo"
							>
								<img
									src="/images/header/thesagergroup_logo.png"
									alt="The Sager Group Logo"
								/>
							</NavLink>
						</div>
						<div className="header__wrapper__menu-icon">
							<img
								src="/images/header/menu.png"
								alt="Menu Icon"
								className="header__wrapper__mobile__hamburger"
								onClick={() => setShowMenu(!showMenu)}
							/>
						</div>
					</div>
				</div>
				<Search />
				{showMenu && (
					<div
						className={classnames("header__wrapper__mobile__menu", {
							"header__wrapper__mobile__menu--big":
								location.pathname === "/" && !scroll,
						})}
					>
						<NavLink activeStyle={{ color: "white" }} exact to="/">
							<span className="header__wrapper__text header__wrapper__text--mobile">
								Home
							</span>
						</NavLink>
						<NavLink activeStyle={{ color: "white" }} exact to="/books">
							<span className="header__wrapper__text header__wrapper__text--mobile">
								Books
							</span>
						</NavLink>
						<NavLink activeStyle={{ color: "white" }} exact to="/makers">
							<span className="header__wrapper__text header__wrapper__text--mobile">
								Makers
							</span>
						</NavLink>
						<NavLink
							activeStyle={{ color: "white" }}
							exact
							to="/stacks-reader-series"
						>
							<span className="header__wrapper__text header__wrapper__text--mobile">
								Stacks Reader Series
							</span>
						</NavLink>
						<NavLink activeStyle={{ color: "white" }} exact to="/tsg-films">
							<span className="header__wrapper__text header__wrapper__text--mobile">
								TSG Films
							</span>
						</NavLink>
						<NavLink activeStyle={{ color: "white" }} exact to="/about">
							<span className="header__wrapper__text header__wrapper__text--mobile">
								About
							</span>
						</NavLink>
						<NavLink activeStyle={{ color: "white" }} exact to="/news">
							<span className="header__wrapper__text header__wrapper__text--mobile">
								News
							</span>
						</NavLink>
						<NavLink
							activeStyle={{ color: "white" }}
							exact
							to="/te-adiuva-press"
						>
							<span className="header__wrapper__text header__wrapper__text--mobile">
								Te Adiuva Press
							</span>
						</NavLink>
						<NavLink activeStyle={{ color: "white" }} exact to="/contact">
							<span className="header__wrapper__text header__wrapper__text--mobile">
								Contact
							</span>
						</NavLink>
					</div>
				)}
			</div>
		</>
	);
};

export default Header;

import classnames from "classnames";
import React, { useState } from "react";

import SocialMedia from "../components/SocialMedia";

import "../styles/contact.scss";

const formDefault = {
	firstName: "",
	lastName: "",
	email: "",
	subject: "",
	message: "",
	subscribe: false,
};

const Contact = () => {
	const [formData, setFormData] = useState(formDefault);
	const [success, setSuccess] = useState(null);
	const [error, setError] = useState(null);

	const encode = (data) => {
		return Object.keys(data)
			.map(
				(key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
			)
			.join("&");
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		fetch("/", {
			method: "POST",
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: encode({
				"form-name": event.target.getAttribute("name"),
				...formData,
			}),
		})
			.then(() => {
				if (formData.subscribe) {
					createRecipientAndSubscribe(formData);
				} else {
					setSuccess("Thank you for reaching out!");
					setFormData(formDefault);
				}
			})
			.catch((error) => {
				setError("Something went wrong. Please try again later.");
			});
		window.scrollTo(0, 0);
		setTimeout(() => {
			setSuccess(null);
			setError(null);
		}, 5000);
	};

	const handleFormUpdate = (e, field) => {
		setFormData({
			...formData,
			[field]: e.target.value,
		});
	};

	const createRecipientAndSubscribe = (data) => {
		const stringData = JSON.stringify([
			{
				email: data.email,
				first_name: data.firstName,
				last_name: data.lastName,
			},
		]);
		fetch("https://api.sendgrid.com/v3//contactdb/recipients", {
			method: "POST",
			headers: {
				authorization: `Bearer ${process.env.REACT_APP_SG_TOKEN}`,
				"content-type": "application/json",
			},
			body: stringData,
		})
			.then((response) => response.json())
			.then((parsed) => {
				const recipientId = parsed.persisted_recipients.length
					? parsed.persisted_recipients[0]
					: null;
				if (recipientId) {
					subscribeRecipient(recipientId);
				}
			})
			.catch((error) => {
				setError(
					"Newsletter subscription could not be completed. Please subscribe through the footer form."
				);
			});
	};

	const subscribeRecipient = (id) => {
		fetch(
			`https://api.sendgrid.com/v3//contactdb/lists/${process.env.REACT_APP_SG_LIST_ID}/recipients/${id}`,
			{
				method: "POST",
				headers: {
					authorization: `Bearer ${process.env.REACT_APP_SG_TOKEN}`,
					"content-type": "application/json",
				},
				body: "{}",
			}
		)
			.then((response) => {
				setSuccess("Thank you for reaching out!");
				setFormData(formDefault);
			})
			.catch((error) => {
				setError(
					"Newsletter subscription could not be completed. Please subscribe through the footer form."
				);
			});
	};

	return (
		<div className="contact container">
			{(success || error) && (
				<div
					className={classnames("contact__thankyou", {
						"contact__thankyou--error": error,
					})}
				>
					<p>{success || error}</p>
				</div>
			)}
			<div className="contact__content">
				<img src="/images/assets/stamp.png" alt="Contact stamp" />
				<form
					name="contact"
					id="contact"
					data-netlify="true"
					netlify-honeypot="bot-field"
					method="post"
					onSubmit={handleSubmit}
				>
					<input type="hidden" name="form-name" value="contact" />
					<div className="contact__content__input">
						<label htmlFor="firstName">
							First Name<sup>*</sup>
						</label>
						<input
							type="text"
							name="firstName"
							required
							value={formData.firstName}
							onChange={(e) => handleFormUpdate(e, "firstName")}
						/>
					</div>
					<div className="contact__content__input">
						<label htmlFor="lastName">
							Last Name<sup>*</sup>
						</label>
						<input
							type="text"
							name="lastName"
							required
							value={formData.lastName}
							onChange={(e) => handleFormUpdate(e, "lastName")}
						/>
					</div>
					<div className="contact__content__input">
						<label htmlFor="email">
							Email<sup>*</sup>
						</label>
						<input
							type="email"
							name="email"
							required
							value={formData.email}
							onChange={(e) => handleFormUpdate(e, "email")}
						/>
					</div>
					<div className="contact__content__input">
						<label htmlFor="subject">Subject</label>
						<input
							type="text"
							name="subject"
							value={formData.subject}
							onChange={(e) => handleFormUpdate(e, "subject")}
						/>
					</div>
					<div className="contact__content__input">
						<label htmlFor="message">Message</label>
						<textarea
							name="message"
							value={formData.message}
							onChange={(e) => handleFormUpdate(e, "message")}
						/>
					</div>
					<div className="contact__content__checkbox">
						<input
							type="checkbox"
							name="subscribe"
							className="checkbox-input"
							checked={formData.subscribe}
							onChange={() =>
								setFormData({ ...formData, subscribe: !formData.subscribe })
							}
						/>
						<label htmlFor="subscribe" className="checkbox-label">
							Subscribe to our Newsletter
						</label>
					</div>
					<div className="contact__content__input">
						<button type="submit" name="submit">
							Submit
						</button>
					</div>
				</form>
			</div>
			<div className="contact__separator"></div>
			<div className="contact__social">
				<SocialMedia />
			</div>
		</div>
	);
};

export default Contact;

import React, { useEffect, useState } from "react";
import Prismic from "@prismicio/client";
import { RichText } from "prismic-reactjs";

import NotFound from "./NotFound";
import { client } from "../utils/prismicHelpers";

import "../styles/te-adiuva-press.scss";

/**
 * Te Adiuva Press page component
 */
const TeAdiuvaPress = () => {
	const [notFound, toggleNotFound] = useState(false);
	const [content, setContent] = useState(null);

	useEffect(() => {
		const fetchPrismicData = async () => {
			try {
				const contentResults = await client.query(
					Prismic.Predicates.at("document.type", "te_adiuva_press")
				);
				if (contentResults.results) {
					setContent(contentResults.results[0]);
				} else {
					console.warn("There is no content for now. Please try again later.");
					toggleNotFound(true);
				}
			} catch (error) {
				console.error(error);
				toggleNotFound(true);
			}
		};

		fetchPrismicData();
	}, []);

	if (content) {
		return (
			<div className="adiuva container">
				<div className="adiuva__title">
					<div className="adiuva__title__content">
						<p>WHAT WE DO</p>
					</div>
				</div>
				<div className="adiuva__main">
					<div className="adiuva__main__subtitle">
						<RichText render={content.data.title} />
					</div>
					{content.data.quotes.map((p, index) => (
						<div className="adiuva__main__quote" key={index}>
							<RichText render={p.quote} />
							<div className="adiuva__main__quote__name">
								<RichText render={p.quote_name} />
							</div>
						</div>
					))}
					<div className="adiuva__main__images">
						<img
							className="adiuva__main__images__item"
							src={content.data.image_1.url}
							alt={content.data.image_1.alt}
						/>
						<img
							className="adiuva__main__images__item"
							src={content.data.image_2.url}
							alt={content.data.image_2.alt}
						/>
					</div>
					<div className="adiuva__main__content">
						{content.data.content.map((p, index) => (
							<div className="adiuva__main__content__paragraph" key={index}>
								<RichText render={p.paragraph} />
							</div>
						))}
					</div>
					<div className="adiuva__main__signature">
						<RichText render={content.data.signature} />
					</div>
					<div className="adiuva__main__note">
						<RichText render={content.data.note} />
					</div>
				</div>
			</div>
		);
	} else if (notFound) {
		return <NotFound />;
	}
	return null;
};

export default TeAdiuvaPress;

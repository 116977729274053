import React, { useEffect, useState } from 'react'
import Prismic from '@prismicio/client';

import NotFound from './NotFound';
import { client } from '../utils/prismicHelpers';

import '../styles/tsg-films.scss'

/**
 * TSG Film Category page component
 */
const TsgFilmCategory = ({ match }) => {
    const [notFound, toggleNotFound] = useState(false);
    const [films, setFilms] = useState(null)
    const [clicked, setClicked] = useState(null)
    const [noWood, setNoWood] = useState(0)
    const [wSize, setWSize] = useState(0)

    const handleResize = () => {
        setWSize(window.innerWidth)
        if (window.innerWidth < 737) {
            setNoWood(Math.ceil(films.length))
        } else {
            setNoWood(Math.ceil(films.length / 2))
        }
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        }
    });

    useEffect(() => {
        const fetchPrismicData = async () => {
            try {
                const response = await client.query(
                    Prismic.Predicates.at('document.tags', [match.params.title])
                );
                if (response.results) {
                    setFilms(response.results)
                    if (window.innerWidth < 737) {
                        setNoWood(Math.ceil(response.results.length))
                    } else {
                        setNoWood(Math.ceil(response.results.length / 2))
                    }
                } else {
                    console.warn('No films data at the moment. Please try again later.');
                    toggleNotFound(true);
                }
            } catch (error) {
                console.error(error);
                toggleNotFound(true);
            }
        }

        fetchPrismicData();
        setWSize(window.innerWidth)
    }, [match.params.title]);

    if (films) {
        return (
            <div className="films">
                <div className="films__title">
                    <div className="films__title__content">
                        <h1>{match.params.title.replaceAll('-', ' ')}</h1>
                    </div>
                </div>
                <div className="films__videos">
                    <div className="films__videos__screens">
                        {films.map((film, index) => {
                            const playUrl = film.data.video_url.provider_name === 'YouTube' ? `${film.data.additional_video_url.url}&autoplay=1` : `${film.data.video_url.embed_url}?autoplay=true`
                            return (
                                <div className="films__videos__video" key={index} onClick={() => setClicked(index)}>
                                    {clicked === index ? (
                                        <iframe
                                            width="254"
                                            height="150"
                                            src={playUrl}
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                            title={film.data.name[0].text}
                                        ></iframe>
                                    ) : (
                                        <>
                                            <img src={film.data.cover.url} alt={film.data.video_url.thumbnail_url} />
                                            <div className="films__videos__video__details">
                                                <p className="films__videos__video__title">{film.data.name[0].text}</p>
                                                {film.data.artist.length > 0 && 
                                                    <p className="films__videos__video__artist">by {film.data.artist[0].text}</p>
                                                }
                                            </div>
                                        </>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                     {[...Array(noWood)].map((item, index) => {
                        const tvHeight = wSize < 1024 ? 248 : 373
                        let topValue = ((index + 1) * tvHeight) - 28
                        const style = {
                            top: `${topValue}px`
                        }
                        return (
                            <div className="books__wood" key={index} style={style}>
                                <div className="books__wood__dark" />
                                <div className="books__wood__light" />
                            </div>
                        )
                    })}
                </div>
            </div>
        );
    } else if (notFound) {
        return <NotFound />;
    }
    return null;
}

export default TsgFilmCategory

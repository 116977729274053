import React, { useEffect, useState } from 'react'
import Prismic from '@prismicio/client';

import NotFound from './NotFound';
import Book from '../components/Book'
import { client } from '../utils/prismicHelpers';

import '../styles/books.scss'

/**
 * Home page component
 */
const Home = () => {
    const [notFound, toggleNotFound] = useState(false);
    const [home, setHome] = useState(null)
    const [hoveredIndex, setHoveredIndex] = useState(null)
    const [blackBooks, setBlackBooks] = useState([])

    useEffect(() => {
        const fetchPrismicData = async () => {
            try {
                const response = await client.query(
                    Prismic.Predicates.at('my.book.show_on_homepage', true),
                    { pageSize : 100, orderings: '[my.book.priority_on_homepage desc]' }
                );
                const blackBooksResults = await client.query(
                    Prismic.Predicates.at('document.type', 'homepage')
                );
                if (response.results) {
                    setHome(response.results);
                } else {
                    console.warn('There is no content for now. Please try again later.');
                    toggleNotFound(true);
                }
                if (blackBooksResults.results) {
                    const blackBooksOrder = blackBooksResults.results[0].data.black_books_order.sort((a, b) => (a.position > b.position ? -1 : 1))
                    const blackBooksArray = blackBooksOrder.map(book => {
                        return {
                            title: book.category,
                            url: getBlackBookUrl(book),
                        }
                    })
                    setBlackBooks(blackBooksArray);
                }
            } catch (error) {
                console.error(error);
                toggleNotFound(true);
            }
        }

        fetchPrismicData();
    }, []);

    const getBlackBookUrl = book => {
        let bookUrl 
        switch (book.category) {
            case 'New Releases':
            case 'Fiction':
            case 'Non Fiction':
                const slug = book.category.toLowerCase().replaceAll(' ','-')
                bookUrl = `/books?tag=${slug}`
                break;
            case 'Mike Sager':
                bookUrl = '/books?author=mike-sager'
                break;
            case 'The Stacks Reader Series':
                bookUrl = '/stacks-reader-series'
                break;
            case 'TSG Films':
                bookUrl = '/tsg-films'
                break;
            default:
                bookUrl = '/books'
        }
        return bookUrl
    }

    if (home && blackBooks.length) {
        return (
            <div className="books home">
                <div className="shelves">
                    <div className="shelves__items">
                        {home.slice(0, 4).map((book, index) => (
                            <Book 
                                key={index}
                                index={index}
                                book={book}
                                href={`/books/${book.uid}`}
                                hovered={hoveredIndex}
                                onMouseEnter={() => setHoveredIndex(index)}
                                onMouseLeave={() => setHoveredIndex(null)}
                            />
                        ))}
                    </div>
                    <div className="shelves__items shelves__items--three">
                        <Book 
                            href={blackBooks[0].url}
                            categoryName={blackBooks[0].title}
                            isCategory
                        />
                        <Book 
                            href={blackBooks[1].url}
                            categoryName={blackBooks[1].title}
                            isCategory
                        />
                        <div className="shelves__items__full-center">
                            <Book 
                                href={blackBooks[2].url}
                                categoryName={blackBooks[2].title}
                                isCategory
                            />
                        </div>
                    </div>
                    <div className="shelves__items">
                        {home.slice(4, 8).map((book, index) => (
                            <Book 
                                key={index}
                                index={index}
                                book={book}
                                href={`/books/${book.uid}`}
                                hovered={hoveredIndex}
                                onMouseEnter={() => setHoveredIndex(index)}
                                onMouseLeave={() => setHoveredIndex(null)}
                            />
                        ))}
                    </div>
                    <div className="shelves__items shelves__items--three">
                        <Book 
                            href={blackBooks[3].url}
                            categoryName={blackBooks[3].title}
                            isCategory
                        />
                        <Book 
                            href={blackBooks[4].url}
                            categoryName={blackBooks[4].title}
                            isCategory
                        />
                        <div className="shelves__items__full-center">
                            <Book 
                                href={blackBooks[5].url}
                                categoryName={blackBooks[5].title}
                                isCategory
                            />
                        </div>
                    </div>
                    <div>
                        <div className="books__wood books__wood--home" style={{ top: '350px' }}>
                            <div className="books__wood__dark" />
                            <div className="books__wood__light" />
                        </div>
                        <div className="books__wood books__wood--home" style={{ top: '810px' }}>
                            <div className="books__wood__dark" />
                            <div className="books__wood__light" />
                        </div>
                        <div className="books__wood books__wood--home" style={{ top: '1320px' }}>
                            <div className="books__wood__dark" />
                            <div className="books__wood__light" />
                        </div>
                        <div className="books__wood books__wood--home" style={{ top: '1780px' }}>
                            <div className="books__wood__dark" />
                            <div className="books__wood__light" />
                        </div>
                        <div className="books__wood books__wood--home" style={{ top: '1840px' }}>
                            <div className="books__wood__dark" />
                            <div className="books__wood__light" />
                        </div>
                        <div className="books__wood books__wood--home" style={{ top: '2230px' }}>
                            <div className="books__wood__dark" />
                            <div className="books__wood__light" />
                        </div>
                        <div className="books__wood books__wood--home" style={{ top: '2620px' }}>
                            <div className="books__wood__dark" />
                            <div className="books__wood__light" />
                        </div>
                        <div className="books__wood books__wood--home" style={{ top: '3010px' }}>
                            <div className="books__wood__dark" />
                            <div className="books__wood__light" />
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (notFound) {
        return <NotFound />;
    }
    return null;
}

export default Home
